<!-- 默认公共布局 -->
<template>
    <div class="default-layout">
        <common-header />
        <div class="router-view">
            <div class="router-view-inner">
                <slot name="routerView" />
            </div>
            <div class="back-top">
                <back-top/>
            </div>
        </div>
        <common-footer />
    </div>
</template>

<script setup lang="ts">
</script>

<style lang="less" scoped>
.default-layout {
    min-width: 1470px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    // 路由出口
    .router-view {
        flex: 1;
        background: #f5f5f5;
        box-sizing: border-box;
        .router-view-inner {
            margin: 0 auto;
            max-width: 1840px;
            box-sizing: border-box;
            padding: 0 20px;
        }
        // 回到顶部
        .back-top{
            @apply fixed;
            bottom: 50px;
            right: 0;
        }
    }
}
</style>